
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormItem } from '@/interfaces/components/FormItem';
import VRichText from '@/components/shared/form/VRichText.vue';
import VLanguageField from '@/components/shared/form/VLanguageField.vue';
import VDateTimePicker from '@/components/shared/form/VDateTimePicker.vue';
import { InputType } from '@/enums/InputType';
import { ValidationObserver } from 'vee-validate';
import VDateRangePicker from '@/components/shared/form/VDateRangePicker.vue';
import VDateMonthPicker from '@/components/shared/form/VDateMonthPicker.vue';
import { nextTick } from 'engine.io-client';
import VDateRangeTimePicker from './VDateRangeTimePicker.vue';
import draggable from 'vuedraggable';
import { toDownloadImage } from '../../../util/helper';
import { map } from 'lodash';

@Component({
  components: {
    VDateRangePicker,
    VLanguageField,
    VRichText,
    VDateTimePicker,
    VDateMonthPicker,
    VDateRangeTimePicker,
    draggable,
  },
})
export default class VFormBuilder extends Vue {
  @Prop({ type: Array, required: true }) public items!: FormItem[];
  @Prop({ type: Object, required: false }) public initialValues!: object;

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  public form: any = {};

  public dragOptions = {
    animation: 250,
    group: 'draggableItems',
  };

  public dragged = {
    from: -1,
    to: -1,
    newIndex: -1,
  };

  protected previews: any = {};

  protected filesToRemove: string[] = [];

  protected selectAllMap: Record<string, boolean> = {};

  public mounted(): void {
    this.update();
  }

  public setData(form: any) {
    this.form = { ...form };
  }

  @Watch('initialValues', { deep: true })
  public onInitialValueChange() {
    this.form = {};
    this.setValues();
  }

  @Watch('selectAllMap', { deep: true })
  public onSelectAllMapChange(newValMap: Record<string, boolean>, oldValMap: Record<string, boolean>) {
    const formKeys: string[] = Object.keys(this.selectAllMap);
    for (const key of formKeys) {
      const field = this.items.find((item: FormItem) => item.name === key) as FormItem;

      const newValue = newValMap[key];
      if (newValue) {
        this.updateValue(field, map(this.getSelectItems(field), field.itemValue));
        this.$forceUpdate();
      }
      if (!newValue) {
        this.form[key].splice(0, this.form[key].length);
      }
    }
  }

  public update() {
    if (!this.initialValues) {
      this.form = {};
    }
    this.setValues();
  }

  public updateValue(item: FormItem, value: any) {
    if (item.name) {
      this.form[item.name] = value;
    }
  }

  public reset() {
    this.form = {};
    this.previews = {};
    this.setValues();
  }

  public setValue(name: string, value: any) {
    this.form[name] = value;
  }

  public clearErrors() {
    this.$refs.observer.reset();
  }

  public setValues() {
    for (const item of this.items) {
      this.form[this.getValueKey(item)] = this.getItemDefaultValue(item);
      if (item.type === InputType.File) {
        this.setPreviewSource(item);
      }
      if (item.type === InputType.DateTime) {
        this.form[`${this.getValueKey(item)}DateMenu`] = false;
        this.form[`${this.getValueKey(item)}TimeMenu`] = false;
      }
    }
  }

  public getSelectItems(item: FormItem): any[] {
    if (typeof item.items === 'object') {
      return item.items;
    }
    if (typeof item.items === 'function') {
      return item.items(this.form);
    }

    return [];
  }

  public getValueKey(item: FormItem): string {
    if (item.value) {
      return item.value;
    }

    return item.name;
  }

  public getItemDefaultValue(item: FormItem): any {
    if (this.initialValues && this.initialValues.hasOwnProperty(this.getValueKey(item))) {
      if (item.type === InputType.File) {
        return null;
      }

      // @ts-ignore
      const val: any = this.initialValues[this.getValueKey(item)];

      if (
        (item.type === InputType.Select || item.type === InputType.Autocomplete || item.type === InputType.Combobox) &&
        val &&
        val[0]
      ) {
        if (typeof val[0] === 'object' && val[0]._id) {
          return val.map((v: any) => v._id);
        }

        return val;
      }

      if (val && val.$numberDecimal) {
        return val.$numberDecimal;
      }

      return val;
    }
    if (item.default !== undefined) {
      return item.default;
    }
    if (
      (item.type === InputType.Select || item.type === InputType.Autocomplete || item.type === InputType.Combobox) &&
      item.multiple === true
    ) {
      return [];
    }
    if (item.type === InputType.Checkbox) {
      return false;
    }
    if (item.type === InputType.Language) {
      return {
        de: '',
      };
    }

    return '';
  }

  public isDisabled(item: FormItem): boolean {
    if (item.hasOwnProperty('disabled')) {
      if (typeof item.disabled === 'boolean') {
        return item.disabled;
      }
      if (typeof item.disabled === 'function') {
        return item.disabled(this.form);
      }
    }
    return false;
  }

  public isReadOnly(item: FormItem): boolean {
    if (item.hasOwnProperty('readonly')) {
      if (typeof item.readonly === 'boolean') {
        return item.readonly;
      }
      if (typeof item.readonly === 'function') {
        return item.readonly(this.form);
      }
    }

    return false;
  }

  public getRules(item: FormItem): string | object {
    if (item.hasOwnProperty('rules')) {
      if (typeof item.rules === 'function') {
        return item.rules(this.form);
      }
      return item.rules as string | object;
    }

    return '';
  }

  public isVisible(item: FormItem): boolean {
    if (item.hasOwnProperty('visible')) {
      if (typeof item.visible === 'boolean') {
        return item.visible;
      }
      if (typeof item.visible === 'function') {
        return item.visible(this.form);
      }
    }

    return true;
  }

  public isRequired(item: FormItem): boolean {
    return !!(item.rules && typeof item.rules === 'string' && item.rules.includes('required'));
  }

  public isRequiredObj(item: FormItem): boolean {
    return !!(item.rules && item.rules.hasOwnProperty('required'));
  }

  public validate() {
    return this.$refs.observer.validate();
  }

  public validateValueAfterMounted() {
    nextTick(() => {
      this.$refs.observer.validate();
    });
  }

  public getFilesToRemove(): string[] {
    return this.filesToRemove;
  }

  public onEnter(item: FormItem): void {
    if (item.onEnter && typeof item.onEnter === 'function') {
      item.onEnter();
      return;
    }
  }

  public moveAutocompleteItem(value: any) {
    this.dragged = {
      from: parseInt(value.from.id),
      to: parseInt(value.to.id),
      newIndex: value.draggedContext.futureIndex,
    };
  }

  public updateAutocompleteItem(value: any, fieldName: string | undefined) {
    if (value.removed && fieldName) {
      let oldValue = [...this.form[fieldName]];
      // insert
      oldValue.splice(this.dragged.to + this.dragged.newIndex, 0, oldValue[this.dragged.from]);
      // delete
      if (this.dragged.from < this.dragged.to) {
        // LTR
        oldValue.splice(this.dragged.from, 1);
      } else {
        // RTL
        oldValue.splice(this.dragged.from + 1, 1);
      }

      this.form[fieldName] = oldValue;
      this.$forceUpdate();
    }
  }

  public getData(): any {
    return this.form;
  }

  protected setPreviewSource(item: FormItem): void {
    const key: string = this.getValueKey(item);
    if (this.form[key] && this.form[key] instanceof File) {
      const index: number = this.filesToRemove.indexOf(key);
      if (index > -1) {
        this.filesToRemove.splice(index, 1);
      }

      if (this.form[key].type.includes('image')) {
        const r: FileReader = new FileReader();
        r.readAsDataURL(this.form[key]);
        r.addEventListener('load', () => {
          this.previews[key] = r.result;
        });
        return;
      } else {
        if (this.previews[key] && this.previews[key] !== null) {
          this.previews[key] = this.form[key].name;
        }
        return;
      }
    }

    // @ts-ignore
    if (this.initialValues && this.initialValues[key] && this.filesToRemove.indexOf(key) < 0) {
      // @ts-ignore
      if (typeof this.initialValues[key] === 'object' && this.initialValues[key].original) {
        // @ts-ignore
        this.previews[key] = this.initialValues[key].original;
        // @ts-ignore
      } else if (typeof this.initialValues[key] === 'string') {
        // @ts-ignore
        this.previews[key] = this.initialValues[key];
      }
    }
  }

  protected removeFile(item: FormItem) {
    if (this.getValueKey(item) === 'assets') {
      this.$emit('removed');
    }

    this.filesToRemove.push(this.getValueKey(item));
  }

  protected async downloadImage(value: string) {
    toDownloadImage(this.previews[value], value);
  }

  protected onIntersect() {
    const autocomplete = this.items.find(
      (item) => (item.type === InputType.Autocomplete && item.name === 'groups') || item.name === 'articles',
    );
    if (autocomplete) {
      autocomplete.loadMore();
    }
  }
}
